import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ACT Competitions",
  "layout": "collabpage",
  "banner": "/images/core/competitions_banner.jpg",
  "banner_caption": "collaborate"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const Obfuscate = makeShortcode("Obfuscate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Image src="/images/competitions/gtoc_trophy.jpg" align="left" caption="The GTOC trophy" width="150px" mdxType="Image" />
    <p>{`At the beginning of the last century Hilbert challenged his colleagues with twenty-three problems in mathematics which proved to be incredibly influential in the coming century.
The competition between Jonas Salk and Albert Sabin was one of the main factors in the quick development of the polio vaccine. The history of flight and space flight is sprinkled with examples
of competitions and prizes that boosted the overall progress. Competition and collaboration are often considered to be irreconcilable concepts. In science, though, the value of offering well defined challenges to a community
and have its members compete to find the best possible solutions has proven a formidable tool to collaborate in the advancement of many fields.`}</p>
    <p>{`With this in mind the ACT has been working in several projects where the benefits of competitions were experimented with.`}</p>
    <h2>{`The Kelvins competitions portal`}</h2>
    <p><a parentName="p" {...{
        "href": "https://kelvins.esa.int/"
      }}>{`Kelvins`}</a>{` is a portal where data-science and algoritmic competition are organized for the benefit of some space related problem.`}</p>
    <p>{`It is envisioned to be a place for space enthusiats from all over the world to set free their creativity and actively participate in the advancement of space and science.`}</p>
    <p>{`The title Kelvins is at the same time the theme for the website and originates from the unit for temperature named after the physicist William Lord Kelvin. To reach the absolute zero error is the target of many machine learning related competitions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://kelvins.esa.int/accounts/register/"
      }}>{`Register`}</a>{` and make sure to receive news on the upcoming competitions.`}</p>
    <h2>{`The Global Trajectory Optimisation Competition`}</h2>
    <p>{`The Global Trajectory Optimization Competition is an event taking place every one-two years over roughly one month during which the best aerospace engineers and mathematicians world wide challenge themselves to solve a “nearly-impossible” problem of interplanetary trajectory design. The problem is released by the winning team of the previous edition who, also, is free to define entirely the competition rules.`}</p>
    <p>{`The problem needs to be related to interplanetary trajectory design and its complexity high enough to ensure a clear competition winner. Over the years, the various problem statements and solutions returned, are collected in the `}<a parentName="p" {...{
        "href": "https://sophia.estec.esa.int/gtoc_portal/"
      }}>{`GTOC portal`}</a>{` and form a formidable database of experiences, solutions and challenges for the scientific community.`}</p>
    <p>{`In 2017 the ACT, who started the competition and participated since, managed to win it with the trajectory described in this video:`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/BIy5g4bvskM" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
    <p>{`The GTOC competitions are opened to everybody, to participate to the next edition, `}<a parentName="p" {...{
        "href": "https://groups.google.com/forum/#!forum/gtoc_competition"
      }}>{`join the GTOC Google Group`}</a>{` and you will receive the next call for participation. In case you are not able to join the group directly, mail your request to `}<Obfuscate email="act.esa@esa.int" mdxType="Obfuscate" />{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      